import { STRINGS } from 'config/index';

export async function checkAuthentication(dispatch) {
  try {
    const response = await fetch(`${STRINGS.API.ROOT_URL}/user-management/users/current`, {
      method: 'GET',
      credentials: 'include',
    });
    if (response.status === 200) {
      dispatch({ type: STRINGS.SETTINGS_REDUCER.CHECK_AUTHENTICATION_SUCCESS });
    } else {
      dispatch({ type: STRINGS.SETTINGS_REDUCER.CHECK_AUTHENTICATION_ERROR });
      window.location.href = `${STRINGS.API.ROOT_URL}/auth/login`;
    }
  } catch (error) {
    dispatch({ type: STRINGS.SETTINGS_REDUCER.CHECK_AUTHENTICATION_ERROR });
    window.location.href = `${STRINGS.API.ROOT_URL}/auth/login`;
  }
}
