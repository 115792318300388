import React from 'react';
import { StyledeCLogo, TitleText, TopBarTitleAndPracticeMenuContainer } from 'styles/blocks/topBar';
import { TopBarContainer } from '@econsult/econsult-ui/dist/components/TopBar/TopBarStyles';
import { TopBarUserActions } from './topBarUserActions';

export function TopBar() {
  return (
    <TopBarContainer shown data-automation="TopBar">
      <TopBarTitleAndPracticeMenuContainer>
        <StyledeCLogo />
        <TitleText>Settings</TitleText>
      </TopBarTitleAndPracticeMenuContainer>
      <TopBarUserActions />
    </TopBarContainer>
  );
}
