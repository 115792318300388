import { STRINGS } from 'config';

export function settingsReducer(settingsState, action) {
  switch (action.type) {
    case STRINGS.SETTINGS_REDUCER.CHECK_AUTHENTICATION_SUCCESS: {
      return { ...settingsState, isLoggedIn: true };
    }
    case STRINGS.SETTINGS_REDUCER.CHECK_AUTHENTICATION_ERROR: {
      return { ...settingsState, isLoggedIn: false };
    }
    default: {
      return settingsState;
    }
  }
}

export const initialState = {
  isLoggedIn: false,
  usersList: [],
};
