import { createContext, useContext } from 'react';

export const SettingsContext = createContext(null);
export const SettingsDispatchContext = createContext(null);

export function useSettingsState() {
  return useContext(SettingsContext);
}

export function useSettingsDispatch() {
  return useContext(SettingsDispatchContext);
}
