import React from 'react';
import { Option as MenuItem, Select as Menu, useSelect as useMenu } from '@econsult/econsult-ui/dist/components/Select';
import { Caret, MenuButton, MenuItems, UserActions } from '@econsult/econsult-ui/dist/components/TopBar/TopBarStyles';

export const TopBarUserActions = () => {
  // Generic props, in a later story these can be removed so that they can be passed in instead
  const user = { username: 'Placeholder Username' };

  const handleLogout = () => {
    // To be implemented in a later story: Logout function
  };

  const { toggleSelect: toggleUserSelect, selectContext: userSelectContext, isOptionsListOpen: isUserSelectOpen } = useMenu();

  return (
    <UserActions>
      <Menu closeOnClickOutside selectContext={userSelectContext} automationName="TopBarUserMenuDropdown">
        <MenuButton onClick={toggleUserSelect} isActive={isUserSelectOpen} data-hj-suppress>{user.username}<Caret /></MenuButton>
        <MenuItems>
          <MenuItem onClick={handleLogout} data-automation="Logout_button">Log out</MenuItem>
        </MenuItems>
      </Menu>
    </UserActions>
  );
};
