import { Div } from 'styles/elements/div';
import styled from 'styled-components';

export const HomeLayout = styled(Div)`
  outline: none;
  flex: 1 1 auto;
`;

export const MainContainer = styled(Div).attrs({ column: true })` 
  flex: 1 1 auto;
  height: 100%;
  align-items: center;
  justify-content: center;
`;
