import React, { useEffect, useState } from 'react';
import { HomeLayout, MainContainer } from 'styles/blocks/Home';
import { TopBar } from 'components/TopBar';
import { checkAuthentication } from 'stateUpdaterFunctions/authentication';
import { useSettingsDispatch, useSettingsState } from 'context/settingsContext';

export function Home() {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const state = useSettingsState();
  const dispatch = useSettingsDispatch();
  useEffect(() => {
    checkAuthentication(dispatch).then(() => setIsAuthenticating(false));
  }, [dispatch]);

  if (isAuthenticating) { return null; }
  return (
    <HomeLayout column>
      <TopBar />
      <MainContainer>
        <h3> 🎛 Settings page  📡</h3>
        <p>isAuthenticating: {isAuthenticating.toString()}</p>
        <p>isLoggedIn: {state.isLoggedIn.toString()}</p>
      </MainContainer>
    </HomeLayout>
  );
}
