import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
    width: 100%;
    font-size: 16px;
  }

  body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.colorNeutralNeutral9};
    font-family: ${({ theme }) => theme.fontFamilyPrimary};

    @media print {
      background-color: ${({ theme }) => theme.backgroundColorShadeOne};

      @page {
        margin: 0.4in 1rem;
      }
    }
  }

  #root {
    display: flex;
    flex: 1 1 auto;

    @media print {
      background-color: ${({ theme }) => theme.backgroundColorShadeOne};
    }
  }

  .react-container {
    display: flex;
    flex: 1 1 auto;
  }

  button:focus,
  a:focus {
    outline: Highlight solid 2px;
    outline: -webkit-focus-ring-color auto 1px;
  }

  .isAnimated {
    --duration: 0.4s;
    --transform-duration: var(--duration);
    --opacity-duration: var(--duration);
    --easing: cubic-bezier(0.65, 0, 0.35, 1);
    --transform-easing: var(--easing);
    --opacity-easing: var(--easing);

    transform-origin: 0% 0%;

    transition:
      transform var(--transform-duration) var(--transform-easing),
      opacity var(--opacity-duration) var(--opacity-easing);

    @media (prefers-reduced-motion) {
      transition-duration: 1ms;
    }
  }

  .intercom-lightweight-app-launcher:focus {
    outline: -webkit-focus-ring-color auto 1px !important;
    box-shadow: none !important;
  }

  button[class^="_hj"]:focus {
    outline: -webkit-focus-ring-color auto 1px;
  }
`;
