export const STRINGS = {
  API: {
    ROOT_URL: 'https://settings.econsult.awshealth.net',
  },
  SETTINGS_REDUCER: {
    CHECK_AUTHENTICATION_ERROR: 'CHECK_AUTHENTICATION_ERROR',
    CHECK_AUTHENTICATION_REQUEST: 'CHECK_AUTHENTICATION_REQUEST',
    CHECK_AUTHENTICATION_SUCCESS: 'CHECK_AUTHENTICATION_SUCCESS',
  },
};
