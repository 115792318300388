import React, { useReducer } from 'react';
import theme from 'styles/theme';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle } from 'styles/globalStyles';
import { Home } from 'components/Home';
import { initialState, settingsReducer } from 'reducer/settingsReducer';
import { SettingsContext, SettingsDispatchContext } from 'context/settingsContext';

function App() {
  const [settingsState, dispatch] = useReducer(
    settingsReducer,
    initialState,
  );
  return (
    <SettingsContext.Provider value={settingsState}>
      <SettingsDispatchContext.Provider value={dispatch}>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <Home />
        </ThemeProvider>
      </SettingsDispatchContext.Provider>
    </SettingsContext.Provider>
  );
}

export default App;
