/* eslint-disable no-console */
import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import { STRINGS } from 'config/constants';
import GitInfo from 'react-git-info/macro';
import App from './components/App/App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

if (STRINGS.ENVIRONMENT.IS_DEV_OR_TEST_ENV) {
  const gitInfo = GitInfo();
  console.log('VERSION INFO');
  console.log('--------------------------------------------------------');
  console.log(`BRANCH * ${gitInfo.branch}`);
  console.log(`GIT COMMIT DATE * ${new Date(gitInfo.commit.date)}`);
  console.log(`LAST MESSAGE * ${gitInfo.commit.message}`);
  console.log(`HASH * ${gitInfo.commit.hash}`);
  console.log(`DATE DEPLOYED * ${process.env.REACT_APP_DEPLOY_DATE}`);
  console.log('--------------------------------------------------------');
}
