import { eCLogo } from 'assets';
import { Paragraph } from '@econsult/econsult-ui/dist/components/shared/typography';
import styled from 'styled-components';
import { Div } from 'styles/elements/div';

export const TopBarTitleAndPracticeMenuContainer = styled(Div)`
  justify-content: center;
  align-items: center;
`;

export const StyledeCLogo = styled(eCLogo)`
  width: 1.6875rem;
  height: 1.6875rem; 
  padding: 0 0.75rem 0 1.5rem;
`;

export const TitleText = styled(Paragraph).attrs({ typography: 'ParagraphBaseHeavy' })`
  margin: 0;
  color: ${({ theme }) => theme.colorPrimaryPrimary30};
  padding: 0 3rem 0 0;
`;
